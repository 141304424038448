import Lightbox from '@/Components/Common/Lightbox.jsx';
import {Fieldset, FieldsetImage} from '@/Pages/Configurator/Styles/Fieldset.jsx';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import {Box, Button, Link as MuiLink, Stack, Typography} from '@mui/material';
import React, {memo} from 'react';

const DefaultOutro = ({amount_max_monthly, openBudgetDetails}) => {
    let decor = {image: '/images/pages/configurator/decors/decor05.jfif', width: 1920, height: 1080};

    return (
        <Fieldset>
            <Box>
                <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                    Aperçu du projet final
                </Typography>
            </Box>

            <Lightbox id={'gallery-budget'}>
                <MuiLink
                    href={decor.image}
                    data-pswp-width={decor.width}
                    data-pswp-height={decor.height}
                    target="_blank"
                    rel="noreferrer"
                >
                    <FieldsetImage src={decor.image} alt="" />
                </MuiLink>
            </Lightbox>

            <Box>
                <Typography variant="fancy_subtitle1" component="p" sx={{mb: {xs: 2, md: 4}}}>
                    Budget estimé du projet
                </Typography>
                <Stack
                    direction="row"
                    sx={{flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', mb: 10}}
                >
                    <Typography variant="h4" component="span" sx={{fontWeight: 600}}>
                        À partir de <br /> {amount_max_monthly} € / mois *
                    </Typography>
                    <MuiLink
                        variant="body1"
                        underline="hover"
                        onClick={openBudgetDetails}
                        sx={{fontStyle: 'italic', color: 'inherit'}}
                    >
                        Voir le détail
                    </MuiLink>
                </Stack>
                <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
                    <Button
                        variant="outlined"
                        color="text"
                        href=""
                        size="large"
                        fullWidth
                        startIcon={<SaveAltOutlinedIcon size="small" />}
                        sx={{mx: 'auto', mb: 1.5, maxWidth: 300}}
                    >
                        Télécharger le récapitulatif PDF
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        endIcon={<ArrowRightAltOutlinedIcon size="small" />}
                        sx={{mx: 'auto', maxWidth: 300}}
                    >
                        Finaliser mon projet
                    </Button>
                </Box>
            </Box>
        </Fieldset>
    );
};

export default memo(DefaultOutro);
